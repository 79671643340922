<template>
  <div class="walkInto">
    <img src="~@/assets/img/comeinto@2x.jpg" class="head_img"/>
    <div class="head_title">构建"医疗健康产业生态圈"</div>
    <div class="striping head_striping"></div>
    <div class="content">
      西安麦菲斯医疗科技有限公司是是国内早期提出“地产医疗”跨界经营新概念的企业。基于地产社区提供医疗服务，引入国际管理式医疗模式，尤其是家庭医生服务体系，倡导“预防医学，医养结合”，从居民日常生活习惯、饮食结构、运动方式等多维度提供周到优质医疗服务保障。
      <br/><br/>
      同时，以互联网为依托，运用大数据、人工智能等先进技术手段，对门诊的服务体验、营运流程进行再造，实现线上线下深度融合，为社区居民创建利于健康的智能居住环境。
      <br/><br/>
    </div>
    <div class="comeinto_bg">
      <div>
        <img src="~@/assets/img/comeinto_detail@2x.png"/>
        <div>
          西安麦菲斯医疗科技有限公司始于2021年，以100余人专业团队，创千万产值规模，版图覆盖多座城市，致力于成为世界优秀的互联网地产+医疗更信赖品牌服务运营商。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "walkInto"
}
</script>

<style scoped lang="scss">
.walkInto {
  .content {
    width: 65%;
    margin: 0 auto;
    font-size: 16px;
    color: #4C4C4C;
    line-height: 32px;
    padding-bottom: 173px;
  }

  .comeinto_bg {
    height: 697px;
    background: url("~@/assets/img/comeinto_bg@2x.png") no-repeat 100%;
    background-size: 100%;

    & > div {
      width: 65%;
      background: #FFFFFF;
      margin: 0 auto;
      padding: 40px 40px 60px 40px;
      position: relative;
      top: 70px;

      & > img {
        width: 100%;
        height: 330px;
      }

      & > div {
        padding-top: 24px;
        font-size: 16px;
        font-weight: 400;
        color: #4C4C4C;
        line-height: 32px;
      }
    }
  }
}
</style>