<template>
  <div class="home">
    <img src="~@/assets/img/home_first_banner@2x.jpg" alt="home_first_banner">
    <div class="home_2nd">
      <div class="home_2nd_content_bg">
        <div class="first">
          <div>垂直深耕</div>
          <div class="striping"></div>
          <div>铸造互联网地产*医疗更信赖品牌</div>
          <div>VERTICAL DEEP PLOUGHING</div>
          <div>CASTING INTERNET REAL ESTATE * MEDICAL MORE TRUST BRAND</div>
        </div>
        <div class="second">
          <div>始终秉承“利他、共赢、循环、永续”的经营之道</div>
          <div>坚持弘扬“低碳、节能、健康、医疗”的开发理念</div>
          <img src="~@/assets/img/home_content_01@2x.png">
        </div>
        <div class="third">
          <img src="~@/assets/img/home_content_02@2x.png">
          <div>以“至臻，致远”为品牌方向</div>
          <div>以“健康、医疗”为品质导向</div>
<!--          <div>致力于人类更健康生活，成为健康领域的领航者</div>-->
        </div>
      </div>
    </div>
    <div class="home_3rd_bg">
      <div>构筑的是社区地产+医疗，更是一条龙服务</div>
      <div class="striping"></div>
      <div style="width: 50%;    margin: 0 auto;">在积极应对人口老龄化与健康中国上升到国家战略的新时代背景下，麦菲斯科技以需求为导向，科学发展，规范运营，通过居家适老化改造服务推动改善老年人居家生活照护条件，增强居家生活设施安全性、便利性和舒适性，提升员工和社区居民的生活幸福指数。</div>
    </div>
    <div class="home_2nd_content">
      <div>
        <img src="~@/assets/img/home_icon1@2x.png"/>
        <div>56</div>
<!--        <div>临时占位符</div>-->
      </div>
      <div>
        <img src="~@/assets/img/home_icon2@2x.png"/>
        <div>456</div>
<!--        <div>临时占位符</div>-->
      </div>
      <div>
        <img src="~@/assets/img/home_icon3@2x.png"/>
        <div>1210</div>
<!--        <div>临时占位符</div>-->
      </div>
      <div>
        <img src="~@/assets/img/home_icon4@2x.png"/>
        <div>28</div>
<!--        <div>临时占位符</div>-->
      </div>
    </div>
    <div class="home_5th_bg">
      <div>
        <div>
          <div>打造开拓新价值</div>
          <div>让更多人享有高品质医疗健康服务</div>
          <div class="striping"></div>
          <div>
            致力于打造互联网医疗为一体的城市医疗集团，并通过运营统筹、流程标准化、采购协同以及IT平台建设等集团化运营手段构筑核心竞争力。与众多国际知名生产厂家、超过50%国内卫生院建立深厚的业务合作关系，让更多人进一步享受到多元化，全方位，高效，专业的健康呵护，开启全新的医疗健康服务体验。
          </div>
        </div>
        <img src="~@/assets/img/home_5th_ctn@2x.png"/>
      </div>
    </div>
    <div class="home_6th">
      <div class="first">
        <div>麦菲斯科技</div>
        <div class="striping"></div>
        <div v-for="(item,index) in navData" :key="index +'nav'" class="third" :class="{activate:activate === index}"
             @click="activate = index">
          {{ item }}
        </div>
      </div>
      <div class="second">
        <template v-if="activate === 0">
          <div style="display: flex">
            <div class="img1" @mouseenter="enter(0)" @mouseleave="leave">
              <span class="describe" v-if="hover === 0">土地作为人类赖以生存的最重要的资源，同样富有生命力。作为一个地产开发者，兴唐地产对每一宗地都怀有敬畏之心。日本江本胜教授《水知道答案》的科学实验，充分证明了世界上万物皆有生命和灵魂，这是科学基础。兴唐地产在建设中，从最初施工就让房子在最美的音乐声中成长。每天播放《心经》、《弟子规》等，把最美的灵气赋予建筑物的每一个分子、每一块材料、每一棵树、每一株草、每一栋建筑，让房地产建筑成为最有生命力的环境。</span>
              <span class="title" v-else>敬畏生命</span>
            </div>
            <div class="img2" @mouseenter="enter(1)" @mouseleave="leave">
              <span class="describe" v-if="hover === 1">推动绿色发展，促进人与自然和谐共生。坚持绿水青山就是金山银山理念，坚持尊重自然、顺应自然、保护自然，坚持节约优先、保护优先、自然恢复为主，守住自然生态安全边界。深入实施可持续发展战略，完善生态文明领域统筹协调机制，构建生态文明体系，促进经济社会发展全面绿色转型，建设人与自然和谐共生的现代化。要加快推动绿色低碳发展，持续改善环境质量，提升生态系统质量和稳定性，全面提高资源利用效率。</span>
              <span class="title" v-else>和谐共生</span>
            </div>
          </div>
          <div style="display: flex">
            <div class="img3" @mouseenter="enter(2)" @mouseleave="leave">
              <span class="describe" v-if="hover === 2">在碳中和时代背景下，在新形势下低碳节能、生命健康、智慧物业、新兴科技、元宇宙等方面的研究及践行成果，将企业、社会、城市与人联系起来，搭建一个绿色金融、科技创新的生态平台。</span>
              <span class="title" v-else>低碳节能</span></div>
            <div class="img4" @mouseenter="enter(3)" @mouseleave="leave">
              <span class="describe" v-if="hover === 3">技术创新能够提升医疗服务的诊疗效果，帮助人们拥有更健康的生活，享受智能化医疗带来的便利。视源股份将投入更多的资源，用科技服务生命。</span>
              <span class="title" v-else>健康医疗</span></div>
          </div>
        </template>
        <template v-if="activate === 1">
          <div style="display: flex">
            <div class="img5" @mouseenter="enter(0)" @mouseleave="leave">
              <span class="describe" v-if="hover === 0">诚实守信是做一切事情的出发点和根本，是企业文化发展的根基。</span>
              <span class="title" v-else>诚信</span>
            </div>
            <div class="img6" @mouseenter="enter(1)" @mouseleave="leave">
              <span class="describe" v-if="hover === 1">致力于互联网地产*医疗更信赖品牌，不仅作为城市的建设者，更重要的是为智慧医疗建设和运营构筑经济的新格局，责任意识是企业的立身之本。</span>
              <span class="title" v-else>责任</span>
            </div>
          </div>
          <div style="display: flex">
            <div class="img7" @mouseenter="enter(2)" @mouseleave="leave">
              <span class="describe" v-if="hover === 2">发展战略清晰，公司在发展过程中，一直脚踏实地地稳步前行。</span>
              <span class="title" v-else>务实</span></div>
            <div class="img8" @mouseenter="enter(3)" @mouseleave="leave">
              <span class="describe" v-if="hover === 3">始终坚持创新进取、与时俱进、追求卓越的精神。</span>
              <span class="title" v-else>进取</span></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      navData: [
        '文化观',
        '价值观'
      ],
      activate: 0,
      hover: '',
    }
  },
  methods: {
    enter(i) {
      this.hover = i
      console.log(i)
    },
    leave() {
      this.hover = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  & > img {
    width: 100%;

    &:nth-child(1) {
      height: 861px;
    }
  }

  .home_2nd {
    height: 1000px;
    background: url("~@/assets/img/home_2nd_bg@2x.png") no-repeat 100%;
    background-size: 100% 100%;
    margin-top: -4px;

    .home_2nd_content_bg {
      width: 67.21%;
      height: 700px;
      background: url("~@/assets/img/home_2nd_content_bg@2x.png") no-repeat 100%;
      background-size: 100% 100%;
      margin: 0 320px;
      position: relative;
      top: 150px;
      display: flex;

      & > div {
        flex: 0.333333;

        &.first {
          & > div {
            text-align: center;

            &:nth-child(1) {
              font-size: 24px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 36px;
              padding-top: 183px;
            }

            &:nth-child(2) {
              margin: 60px auto;
            }

            &:nth-child(3) {
              font-size: 18px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 27px;
              margin-bottom: 214px;
            }

            &:nth-child(4),
            &:nth-child(5) {
              font-size: 12px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 20px;
            }
          }
        }

        &.second {
          div {
            &:first-child {
              margin-top: 66px;
            }

            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
          }

          img {
            margin-top: 66px;
          }
        }

        &.third {
          div {
            &:nth-child(2) {
              margin-top: 60px;
            }

            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
          }
        }

        img {
          width: 100%;
          height: 500px;
        }
      }

    }
  }

  .home_3rd_bg {
    height: 400px;
    background: url("~@/assets/img/home_3rd_bg@2x.png") no-repeat 100%;
    background-size: 100% 100%;
    text-align: center;

    & > div {
      &:nth-child(1) {
        padding-top: 100px;
        font-size: 36px;
        font-weight: bold;
        color: #4C4C4C;
        line-height: 54px;
        letter-spacing: 1px;
      }

      &:nth-child(2) {
        margin: 50px auto 80px;
        background: #DADBDD;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        font-size: 16px;
        color: #4C4C4C;
        line-height: 32px;
      }
    }
  }

  .home_2nd_content {
    height: 465px;
    background: url("~@/assets/img/home_4th_bg@2x.png") no-repeat 100%;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    padding: 170px 390px 0;

    & > div {
      text-align: center;

      img {
        width: 180px;
        height: 180px;
      }

      & > div {
        &:nth-child(2) {
          font-size: 40px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 49px;
          letter-spacing: 1px;
          margin-top: 29px;
        }

        &:nth-child(3) {
          font-size: 24px;
          color: #FFFFFF;
          line-height: 32px;
          margin-top: 6px;
        }
      }
    }
  }

  .home_5th_bg {
    height: 1000px;
    background: url("~@/assets/img/home_5th_bg@2x.png") no-repeat 100%;
    background-size: 100% 100%;


    & > div {
      position: relative;
      top: 16%;
      left: 16.5%;
      display: flex;
      transition: all 0.5s ease-out;
      width: 67.3%;

      &:hover {
        transform: scale(1.03);
      }

      & > div {
        width: 33.35%;
        height: 700px;
        background: #BE9A7E;

        & > div {
          text-align: center;

          &:nth-child(1) {
            margin-top: 138px;
          }

          &:nth-child(1),
          &:nth-child(2) {
            font-size: 24px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 36px;
          }

          &:nth-child(3) {
            margin: 46px auto;
          }

          &:nth-child(4) {
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
            padding: 0 36px;
          }
        }
      }

      img {
        width: 67.5%;
        height: 700px;
      }
    }
  }

  .home_6th {
    display: flex;
    height: 860px;

    .first {
      width: 25%;
      background: #BE9A7E;
      //border: 1px solid #979797;

      & > div {
        text-align: center;

        &:nth-child(1) {
          padding: 303px 0 110px;
          font-size: 24px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 36px;
        }

        &:nth-child(2) {
          margin-bottom: 46px;
        }

        &.third {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 20px;
          padding-bottom: 20px;
          cursor: pointer;
          opacity: .4;
          transition: all 1s ease-out;

          &.activate {
            opacity: 1;
          }
        }
      }
    }

    .second {
      width: 75.2%;
      font-size: 0;

      & > div {
        & > div {
          display: flex;
          width: 50%;
          height: 430px;


          .title {
            display: block;
            margin: 30px;
            font-size: 22px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            transition: all 0.1s linear;
          }

          .describe {
            display: block;
            margin: 30px;
            font-size: 16px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
            transition: all 0.1s linear;
          }

          background-size: 100% 100% !important;

          &.img1 {
            background: url('~@/assets/img/culture_01@2x.png') no-repeat;
          }

          &.img2 {
            background: url('~@/assets/img/culture_02@2x.jpg') no-repeat;
          }

          &.img3 {
            background: url('~@/assets/img/culture_03@2x.png') no-repeat;
          }

          &.img4 {
            background: url('~@/assets/img/culture_04@2x.jpg') no-repeat;
          }

          &.img5 {
            background: url('~@/assets/img/values_01@2x.png') no-repeat;
          }

          &.img6 {
            background: url('~@/assets/img/values_03@2x.jpg') no-repeat;
          }

          &.img7 {
            background: url('~@/assets/img/values_02@2x.jpg') no-repeat;
          }

          &.img8 {
            background: url('~@/assets/img/values_04@2x.jpg') no-repeat;
          }
        }
      }
    }
  }
}
</style>
